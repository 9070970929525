import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import postApiCall from "../../Services/postApiCall";
import { Button } from "react-bootstrap";
import PaginationNew from "../../Widgets/PaginationNew";
import deleteApiCall from "../../Services/deleteApiCall";
import Constant from "../../Components/Constant";
import { userDetails } from "../../Services/authenticationService";
import useGetRoleModule from "../../Services/useGetRoleModule";
import axios from "axios";
import loginUser from "../../Services/loginUser";
import { TagPicker } from 'rsuite';
import moment from 'moment'
import { DateRangePicker, Stack} from 'rsuite';
import "rsuite/dist/rsuite.css";
import getApiCall from "../../Services/getApiCall";
import { SelectPicker, Tooltip, Whisper, } from 'rsuite';


const AuctionPropertyListing = () => {
  let { accessToken } = loginUser();


  const [lists, setLists] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(Constant.perPage);
  const [isLoaded, setIsLoaded] = useState(false);
  const [partnerList, setPartnerList] = useState([])
  const [searchForm, setSearchForm] = useState({
    searchKey: "",
    fromDate: "",
    toDate: "",
    soldStatus: "",
    stateName: "",
    cityName: "",
  });
  const [permission, setPermission] = useState({});
  const [auctionPermission, setAuctionPermission] = useState({});
  const [partnerArr, setPartnerArr] = useState([])
  const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
  const [partner, setPartner] = useState("")
  const [hoverText,setHoverText] = useState('')

  const user = userDetails();

  function changeSearchForm(e) {
    if (!e.target.name) return;
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value,
    });
  }

  const onChangeFilter = (e) => {
    if (!e.target.name) return;
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value,
    });
  };
  const submitFilter = (e) => {
    e.preventDefault();
    getList(1);
    // getEmenities()
  };

  async function getList(page) {
    let response = await postApiCall("user/property/get-all", {
      page: page,
      contentPerPage: perPage,
      searchKey: searchForm.searchKey,
      fromDate: searchForm.fromDate,
      toDate: searchForm.toDate,
      soldStatus: searchForm.soldStatus,
      propertyFor: 2,
      partnerArr,
      stateName: searchForm.stateName,
      cityName: searchForm.cityName,
    }, true);

    //  let partnerLis = await postApiCall("admin/partner/getPartnerUser", {}, true);
    //  if(partnerLis.meta.status){
    //      let out = partnerLis.data.map(_ => {
    //          return {
    //            label: _.parnterName, value: _.partnerId 
    //          }
    //      })
    //      setPartnerList(out)
    //  }
 
    setLists(response.data);
    setTotalItems(response.total);
    setIsLoaded(true);
    getState()
  }

  async function tagPickerHandler(e){
    setPartnerArr(e)
  }

  async function GetRole() {
    let Role = await useGetRoleModule("properties/auction");
    if (Role.moduleList.read === false) {
      setPermission({
        moduleAccress: false,
        moduleList: {},
        message: "Module Need Some Permission...Pls contact with Your Partner",
      });
    } else {
      if(Role.role == "admin"){
        let partnerLis = await postApiCall("admin/partner/getPartnerUser", {}, true);
        if(partnerLis.meta.status){
            let out = partnerLis.data.map(_ => {
                return {
                  label: _.parnterName, value: _.partnerId 
                }
            })
            setPartnerList(out)
            setPartner(<div className="w-10 ml-2">
            <div className="form-group">
          
             <TagPicker placeholder="Select Tags" data={out} style={{ width: 300 }} onChange={(e) => tagPickerHandler(e)}/>
          </div>
          </div>)
        }
       
      }
      
      setPermission(Role);
    }

    // getList();
  }

  useEffect(() => {
    GetRole();
  }, []);

  async function getState() {
    let response = await getApiCall('admin/state/getAll');
    if (response.meta.status == true) setStateList(response.data.map(_ => {
        return {value: JSON.stringify({isoCode:_.isoCode, name: _.name}), label: _.name}
    }))
    setStateList(old => [...old ])
}

const stateHandler = (selectedObj) => {
    if(selectedObj){
      selectedObj = JSON.parse(selectedObj)
      setSearchForm({ ...searchForm, stateName: selectedObj.name});
      GetCity(selectedObj.isoCode);
    }
}

const cityHandler = (selectedObj) => {
  if(selectedObj) setSearchForm({ ...searchForm, cityName: selectedObj});
}

const GetCity = async (selectedObj) => {
    if(selectedObj){
    let response = await getApiCall(`admin/city/getAllForOption?isoCode=${selectedObj}`);
    if (response.meta.status == true) setCityList(response.data.map(_ => {
        return {value: _.name, label: _.name}
    }))
    // setRequ({name: "stateId", value})
    // setRequ({name: "stateName", value: label})
}
}


  function UpdateStatus(e) {
    let id = e.currentTarget.getAttribute("value");
    let status = e.currentTarget.getAttribute("status");
    let isDeleted = e.currentTarget.getAttribute("isDeleted");
    // console.log('status', status)
    // status = status === 1 ? "active" : "deactive"
    status = status == 0 ? "active" : "deactive";
    // console.log('status', status)

    postApiCall("user/property/status", {
      status: status,
      _id: id,
      // isDeleted: isDeleted
    }).then((response) => {
      if (response.meta.status) {
        swal({ text: response.meta.msg, icon: "success", timer: 1500 });
        getList(1);
      }
    });
  }

  function DeleteEvent(e) {
    let id = e.currentTarget.getAttribute("value");
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApiCall("user/property/delete/" + id, {}).then((response) => {
          if (response.meta.status) {
            swal({ text: response.meta.msg, icon: "success", timer: 1500 });
            getList(1);
          }
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  }

  const exportToExcel = async () => {
    try {
      const requestData = {
        searchKey: searchForm.searchKey,
        fromDate: searchForm.fromDate,
        toDate: searchForm.toDate,
        soldStatus: searchForm.soldStatus,
        propertyFor: 2,
        partnerArr,
        stateName: searchForm.stateName,
        cityName: searchForm.cityName,
      };

      const response = await axios({
        url: Constant.apiBasePath + "user/property/download",
        method: "POST", // Changed to POST
        responseType: "blob",
        headers: {
          authkey: accessToken,
          "Content-Type": "application/json", // Added content type for POST request
        },
        data: JSON.stringify(requestData), // Send data in the request body
      });

      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "properties.xlsx";
      document.body.appendChild(link);
      link.click();
      // Clean up the URL object when the download is complete
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  function convertDate(date) {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month?.length < 2) month = "0" + month;
    if (day?.length < 2) day = "0" + day;
    return [day, month, year].join("-");
  }

  const dateRangeHandler = (e) => {
    if(e){
   
        let fromDate = e[0]
        let toDate = e[1]
        fromDate = moment(fromDate).format('YYYY/MM/DD');
        toDate = moment(toDate).format('YYYY/MM/DD');
        setSearchForm({
          ...searchForm,
          fromDate,
          toDate
        });

    }else{
      setSearchForm({
            ...searchForm,
            fromDate:"",
            toDate:""
        })
        console.log("DATE RANGE CLEARED")
    }
}

  function pageChangeHandler(page) {
    if (isLoaded) {
      setPageNo(page);
      // getList(page);
    }
  }
  function truncateText(value) {
    let truncated = value?.length < 50 ? value :value?.substring(0, 50) + "...";
    return truncated;
  }

  function handleMouseOver(e){
    if(e?.length < 50){
      return 
    }
    setHoverText(e)
  }
  useEffect(() => {
    getList(pageNo);
  }, [pageNo]);

  return (
    <>
      <div className="container-fluid">
        <div className="main-title">
          <h3>Auction Property Management</h3>
        </div>

        {permission.hasOwnProperty("moduleAccress") &&
        !permission.moduleAccress ? (
          <div className="row text-center">
            <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
              <div className="errer">
                <img src="/program-error.png" />
                <h2>403</h2>
                {/* <h4 className="text-danger">{permission.message}</h4> */}
                <p>{permission.message}</p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-sm-flex align-items-center justify-content-end mb-4">
             
               { !permission?.moduleList?.create ? null : (
                  <Link
                    to="/property/auction/add"
                    className="d-sm-inline-block btn  btn-primary shadow-sm"
                  >
                    Add Auction Property
                  </Link>
                )}
              
              <Button
                onClick={exportToExcel}
                className="d-sm-inline-block btn  btn-primary shadow-sm ml-1"
              >
                Download to Excel
              </Button>
            </div>

            <div className="card shadow mb-4">
              {/*Search and Filter From*/}
              <div className="card-body">
                <form onSubmit={submitFilter} onChange={onChangeFilter}>
                  <div className="d-flex flex-row">
                    <div className="col-12 col-xs-12 col-md-12 col-lg-12">
                      <div className="d-flex flex-row ">
                        <div className="w-15">
                          <div className="form-group">
                          {/* <b style={{ color:'#000000'}}>Search </b> */}
                            <input
                              type="text"
                              className="form-control"
                              name="searchKey"
                              value={searchForm.searchKey}
                              placeholder="Search By Partner Name, Property ID, City, State, Address, Project Name"

                            />
                          </div>
                        </div>

                       

                        <div className="ml-1">
                        {/* <b style={{ color:'#000000'}}>Date </b> */}
                          <div className="form-group">
                           
                            <DateRangePicker placeholder={'Select date '} onChange={(e) => dateRangeHandler(e)}/>
                          </div>
                        </div>
                      
                        <div className="w-15 ml-2">
                          <div className="form-group">
                          {/* <b style={{ color:'#000000'}} htmlFor="exampleFormControlSelect1">
                              Sold Status
                            </b> */}
                            <select
                              name="soldStatus"
                              value={searchForm.soldStatus}
                              className="form-control"
                              
                            >
                               <option value="">Select Sold Status</option>
                              <option value="YES">Yes</option>
                              <option value="NO">No</option>
                            </select>
                          </div>
                        </div>
                       
                        {partner}
             
                        {/* load partner dropdown only for admin */}


                        <div className="w-10 ml-2">
                          <div className="form-group">
                        
                            <SelectPicker placeholder="Select State" data={stateList} style={{ width: 224 }} onChange={(e) => stateHandler(e)}/>
                        </div>
                        </div>

                        <div className="w-10 ml-2">
                          <div className="form-group">
                         
                            <SelectPicker placeholder="Select City" data={cityList} style={{ width: 224 }} onChange={(e) => cityHandler(e)}/>
                        </div>
                        </div>

                        
                    
                      </div>
                    </div>
                    <div style={{'margin-left': '-9rem'}} >
                      <div className="form-group ">
                        <label> </label>
                        <button
                          type="submit"
                          className="btn btn-md btn-primary shadow-sm  mr-2"
                        >
                          {" "}
                          Search & Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th>Auction Id</th>
                        <th>Property Id</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Address / Location</th>
                        <th>Financial Institution Name</th>
                        <th>Auction Date</th>
                        <th>Upload Date</th>
                        {/*<th>Type Of Property</th>*/}
                        {/*<th>Feedbacks</th>*/}
                        <th>Property Status</th>
                        {/*{user.serviceType === 2 || user.role == 'admin' ? <th>Bids</th> : ''}*/}
                        {/*{user.serviceType === 2 ? <th>Bids</th> : ''}*/}
                        {permission?.moduleList?.deleteDisabled &&
                        permission?.moduleList?.updateDisabled ? null : (
                          <th>Action</th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {
                      lists?.length > 0 ?
                      lists.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1 + (pageNo - 1) * 10}</td>
                            
                            <td>
                              <Link to={"/property/details/" + item._id}>
                                {item.auctionId}
                              </Link>
                            </td>
                            <td>
                                {item.propertyId}
                            </td>
                            <td>{item.stateName || ''}</td>
                            <td>{item.cityName || ''}</td>
                            <td className="new-tooltip" onMouseOver={()=> handleMouseOver(item?.address)}>{truncateText(item?.address)}
                            {
                              item.address?.length > 50 ?
                              <span>{hoverText}</span>
                              :<></>
                            }
                           
                            </td>
                            <td>{item.partner ? item.partner.name : ""}</td>
                            <td>{convertDate(item.auctionStartDateTime)}</td>
                            <td>{convertDate(item.createdAt)}</td>
                            {/*<td>{item.createdAt ? }</td>*/}
                            {/*<td>{item.propertyType == 1 ? 'Residential' : 'Commercial'}</td>*/}
                            {/*<td><Link className={'btn btn-warning btn-sm'}*/}
                            {/*          to={'/properties/feedbacks/' + item._id}>Feedbacks</Link>*/}
                            {/*</td>*/}
                            <td>{item.status ? "Active" : "Inactive"}</td>

                            {/*{user.serviceType && item.propertyFor === 2 ?*/}
                            {/*    <td><Link className={'btn btn-warning btn-sm'}*/}
                            {/*              to={'/properties/bids/' + item._id}>Bids</Link>*/}
                            {/*    </td> : ''}*/}
                            {permission?.moduleList?.deleteDisabled &&
                        permission?.moduleList?.updateDisabled ? null : (
                            <td className="d-flex flex-row">
                              {permission?.moduleList
                                ?.updateDisabled ? null : !item.status ? (
                                <button
                                  onClick={UpdateStatus}
                                  value={item._id}
                                  isDeleted={item.isDeleted}
                                  status={item.status}
                                  className="btn btn-info btn-icon-split btn-sm  mb-1 mr-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="fas fa-exclamation-triangle"></i>
                                  </span>
                                  {/*<span className="text">Disable</span>*/}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-icon-split btn-sm mb-1 mr-1"
                                  onClick={UpdateStatus}
                                  value={item._id}
                                  isDeleted={item.isDeleted}
                                  status={item.status}
                                >
                                  <span className="icon text-white-50">
                                    <i className="fas fa-check"></i>
                                  </span>
                                </button>
                              )}

                              {permission?.moduleList?.updateDisabled ? null : (
                                <Link
                                  to={"/property/auction/add?id="+ item._id}
                                  className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="far fa-edit"></i>
                                  </span>
                                  {/*<span className="text">Edit</span>*/}
                                </Link>
                              )}

                              {permission?.moduleList?.deleteDisabled ? null : (
                                <button
                                  onClick={DeleteEvent}
                                  value={item._id}
                                  className="btn btn-danger btn-icon-split btn-sm mb-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="fa fa-trash"></i>
                                  </span>
                                  {/*<span className="text">Delete</span>*/}
                                </button>
                              )}
                              <Whisper followCursor placement="left" speaker={<Tooltip>Audit Trail</Tooltip>}>
                              <a href={`/properties/audit-trails/${item._id}`}><button className="btn btn-icon-split btn-sm mb-1 ml-1" style={{backgroundColor: 'black'}}>
                                <span className="icon" style={{color: 'white'}}>
                                   <i class="fa fa-history" aria-hidden="true"></i>
                                </span>
                              </button></a>
                              </Whisper>
                            </td>)}
                          </tr>
                        );
                      }) : <tr><td colSpan={12} style={{textAlign: 'center'}}>No records</td></tr>}
                    </tbody>
                  </table>
                  <div className="justify-content-center mt-2">
                    <PaginationNew
                      perPage={perPage}
                      totalItems={totalItems}
                      currentPage={pageNo}
                      handler={pageChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AuctionPropertyListing;
