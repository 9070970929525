const Constant = {
    MAX_SELECT_OPTION_SIZE: 100,
    APP_NAME: "ONE HOME",
    'statusSuccess': 'Success',
    'statusFailure': 'Failure',
    'perPage': 20,
    "successCode": 200,
    "updateSuccessCode": 202,
    'apiBasePath': 'https://85cd-122-161-67-247.ngrok-free.app/api/',
    SSL_PINNING: "sha256/bMu9l9+NKsH+CxaWm1dgCbrQxfSiZ/N3M6//qhA8cIE=",
    encryptionSecretKey: "nZr4u7x!A%D*G-KaPdSgVkXp2s5v8y/B",

}
if (process.env.NODE_ENV === 'production') {
//    Constant.apiBasePath = 'https://api-uat.iiflonehome.com/api/';
//  Constant.socketPath = "https://api-uat.iiflonehome.com"
 Constant.SSL_PINNING = "sha256/rwKAcFjQUQtsyMT7sDiuSNoIoSYkb3kTWQ2dlwQ1fNk="
   Constant.apiBasePath = 'https://api.iiflonehome.com/api/';
   Constant.socketPath = "https://api.iiflonehome.com"

} else {
    // Constant.apiBasePath = 'http://13.234.187.136:8087/api/';
    // Constant.socketPath = "http://13.234.187.136:8087"
    // Constant.apiBasePath = 'https://5c17-122-161-73-25.ngrok-free.app/api/';
    // Constant.socketPath = "http://localhost:8087"
    Constant.apiBasePath = 'https://api-uat.iiflonehome.com/api/';
  Constant.socketPath = "https://api-uat.iiflonehome.com"
    // // Constant.apiBasePath = 'http://localhost:8087/api/';
    //Constant.socketPath = "https://api-uat.iiflonehome.com/api/"
      Constant.apiBasePath = 'https://api.iiflonehome.com/api/';
   Constant.socketPath = "https://api.iiflonehome.com"
   
   
}


module.exports = Constant;