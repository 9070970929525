
import React, {useEffect, useState} from 'react'
import swal from 'sweetalert';
import Select from 'react-select';
import {FilePond,} from "react-filepond";
import Constant from "../../../Components/Constant";
import loginUser from "../../../Services/loginUser";
import {blobUrl} from "../../../Services/helpers";
import getApiCall from "../../../Services/getApiCall";
import postApiCall from "../../../Services/postApiCall";

let {accessToken} = loginUser();



const PropertyManagement = (props) => {
    let id = (new URLSearchParams(window.location.search)).get("id");
   
    const [isReady, setIsReady] = useState(true);
    const [data, setData] = useState({
         "prospectId": "",
         "address": "",
         categories: [],
         videoUrls: [],
     });
    const [videoUrls,setVideoUrls] = useState([])

    const [categories, setCategories] = useState([]);
    const [categoriesOption, setCategoriesOption] = useState([]);
    const [categoriesDocs, setCategoriesDocs] = useState({});
    const [categoryImage, setCategoryImage] = useState({});
    const [thumbail,setThumbnail] = useState('')
    const [editDocument, setEditDocument] = useState([]);
    const [documentUploadShow, setDocumentUploadShow] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [documentUrl, setDocumentUrl] = useState('');
    
    useEffect(() => {
               
                getApiCall('common/category/get-all').then((response) => {
                    if (response.meta.msg && response.data) {
                        let options = response.data.map((item) => {
                            return {value: item._id, label: item.title}
                        })
                        setCategoriesOption(options)
                    }
                });
        
           

    }, []);
    useEffect(() => {
        
        let {category, image} = categoryImage;

       let imageObject = {
        "url": image,
        "status":"pending",
        "imageThumbnail": thumbail
        }
        let cat_object = {};
        if (!category) {
             return;
        }
        if (!categoriesDocs[category.value]) {
            cat_object = {
                "title": category.label,
                "images": [imageObject]
            }

        } else {
            cat_object = {
                "title": category.label,
                "images": [...categoriesDocs[category.value].images, imageObject]
            }
        }
        setCategoriesDocs({
            ...categoriesDocs, [category.value]: cat_object
        });

    }, [thumbail])

    const handleDocumentUploadClose = () => {
        setDocumentUploadShow(false);
        setDocumentName('');
        setDocumentUrl('');
    }
    const handleDocumentAdd = () => {
        //require name and url
        if (documentName === '' || documentUrl === '') {
            //sweet alert
            swal("Please enter document name and  Upload document");
            return;
        }
        setData({
            ...data,
            propertyDocument: [...data.propertyDocument, {
                name: documentName,
                image: documentUrl
            }]
        })
        handleDocumentUploadClose();
    };

   

 useEffect(() => {
        let items = [];
        let docs = {};
        editDocument.map((item) => {
            let am = categoriesOption.find((cat) => cat.label === item.type)
            if (am) {
                items.push(am);
                // {title: 'Bedroom updated', images: Array(0)}
                docs[am.value] = {title: item.type, images: item.images}
            }
        })
        setCategories(items);
        setCategoriesDocs(docs);

    }, [editDocument, categoriesOption]);


    const onChange = (e) => {
        if (!Object.keys(data).includes(e.target.name)) return;

        switch (e.target.name) {
            case 'cityName':
                let city = '';
                if (city) {
                   
                }
                break;
            default:
                if (e.target.name) {
                    //if field type is number then only allow positive number
                    if (e.target.type === 'number' && e.target.value && !isNaN(e.target.value)) if (e.target.value < 0) return ''
                    setData({
                        ...data, [e.target.name]: e.target.value
                    })
                }
                break;


        }


    }
    const Save = async (form_data) => {
        // if (!form_data.latitude) delete form_data.latitude;
        // if (!form_data.longitude) delete form_data.longitude;
        if (!form_data.prospectId) delete form_data.prospectId;
       
        setIsReady(false)
      let returnData = await  postApiCall('user/property/media/upload', form_data, true)
                if (returnData.meta.status) {
                    setIsReady(true)
                    // swal({text: returnData.meta.msg, icon: "success", timer: 1500})
                    swal({
                        text: returnData.meta.msg, icon: "success",
                        timer: 5000
                    })
                    setData({
                        ...data, 
                        "prospectId": "",
                            "address": "",
                            categories: [],
                            videoUrls: [],
                      })
                    setCategories([])
                    setVideoUrls([])
                }else{
                    // swal({
                    //     text: returnData.meta.msg, icon: "warning",
                    //     timer: 5000
                    // })
                    setData({
                        ...data, 
                        "prospectId": "",
                            "address": "",
                            categories: [],
                            videoUrls: [],
                    })
                    setCategories([])
                }
          
    }

   
    function removeCategoryPreviewImage(index, categoryId) {
        if (categoriesDocs[categoryId]) {
            categoriesDocs[categoryId].images = categoriesDocs[categoryId].images.filter((item, i) => i !== index);
            setCategoriesDocs({
                ...categoriesDocs, [categoryId]: categoriesDocs[categoryId]
            });
        }

       // setData({...data, [stateName]: data[stateName].filter((item, i) => i !== index)})
    }

    function addVedioUrl(url){
        let vediopayload = {
            "url": url,
            "status": 'pending'
        }
       videoUrls.push(vediopayload)
        setVideoUrls(videoUrls)
    }

    const onSubmit = async (e) => {
        if (!isReady) return
        e.preventDefault()
        // data.document = categoriesDocs;
        //category docs convert to array and remove key
        let categoryDocs = [];
        for (const [key, value] of Object.entries(categoriesDocs)) {
            categoryDocs.push(value);
        }
        data.categories = categoryDocs;
        data.videoUrls = videoUrls;
        // delete data.buildingType;

        if (id) {
            data.id = id;
            Save(data);
        } else {
            Save(data);
        }
    }

    const generateThumbnail =async (imageurl,prospectId)=>{
        let payload = {
            "imageUrl":imageurl,
            "prospectId":prospectId
            }
        let returnData = await postApiCall('common/upload/blob/admin/generateImageThumbnail', payload, true);
        // console.log('returnData',returnData)
        if (returnData.meta.status) {
            setThumbnail(returnData.data)
            swal({text: returnData.meta.msg, icon: "success", timer: 1000})
           
        }
    }


    return (<>
        <div className="container-fluid">
            
    
            <div className="card shadow mb-4">

                <div className="card-body">
                    <form onChange={onChange} onSubmit={onSubmit} className="hide-number-input-arrow">
                        <div className="row">
                    
                            <div className="col-12 col-xs-4 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label>Prospect Number *</label>
                                    <input type="text" name="prospectId" className="form-control"
                                           value={data.prospectId} required={true}/>
                                </div>
                            </div>

                            <div className="col-12 col-xs-4 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label>Property Address</label>
                                    <input type="text" name="address" className="form-control" 
                                           value={data.address}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Select Categories For Property Image
                                            Upload</label>
                                        <Select
                                            value={categories}
                                            onChange={setCategories}
                                            options={categoriesOption}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                                {categories.map((category, index) => {
                                    return (<div className="card" key={category.value}>
                                        <div className="card-body">
                                            <h5 className="card-title text-center">{category.label}</h5>
                                            <div className="img-preview">
                                                {categoriesDocs[category.value] && categoriesDocs[category.value].images.map((item, index) => (
                                                    <div className="img-preview__item" key={item}>
                                                        <img src={blobUrl(item)} alt
                                                             className="img-preview__image"/>
                                                        <button type="button" className="img-preview__remove"
                                                                onClick={() => {
                                                                    removeCategoryPreviewImage(index, category.value)
                                                                }}>X
                                                        </button>
                                                    </div>))}
                                            </div>
                                            <FilePond
                                                maxFileSize="20MB"
                                                allowFileTypeValidation={true}
                                                acceptedFileTypes={['image/*']}
                                                imagePreviewMaxHeight={100}
                                                credits={false}
                                                allowMultiple={true}
                                                allowRevert={false}
                                                name="image"
                                                labelIdle='Drag & Drop your files(Supported file formats: JPG, JPEG, PNG) or <span class="filepond--label-action">Browse</span>'
                                                server={{
                                                    url: Constant.apiBasePath + 'common/upload/blob/admin/image',
                                                    process: {
                                                        headers: {
                                                            authkey: accessToken
                                                        },
                                                        onload: (res) => {
                                                            // if file is last
                                                            let datas = JSON.parse(res);
                                                            let cat_obj = {};

                                                            if (datas.meta.status) {
                                                                // when category Image Update use-effect catch the state change, and update setCategory State
                                                                setCategoryImage({
                                                                    category: category,
                                                                    image: datas.data
                                                                })
                                                                generateThumbnail(datas.data,data.prospectId)
                                                             
                                                            } else {
                                                                swal("use jpg and png file only");

                                                            }
                                                        }

                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>)
                                })}
                                  <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Upload Video</h5>
                                        <FilePond
                                            maxFileSize="500MB"
                                            allowFileTypeValidation={true}
                                            acceptedFileTypes={[
                                                'video/mp4'
                                            ]}
                                            imagePreviewMaxHeight={100}
                                            credits={false}
                                            allowMultiple={true}
                                            allowRevert={false}
                                            name="video"
                                            labelIdle='Drag & Drop  .mp4 files (Supported Video formats: MP4, MOV, AVI, WMV) or <span class="filepond--label-action">Browse</span>'
                                            server={{
                                                url: Constant.apiBasePath + 'common/upload/blob/admin/video',
                                                process: {
                                                    headers: {
                                                        authkey: accessToken
                                                    }, onload: (res) => {

                                                        res = JSON.parse(res);
                                                        if (res.meta.status) {
                                                            addVedioUrl(res.data)
                                                            
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                            </div>

                        </div>
                        <div className="form-group mt-1">
                            {
                                isReady ? <button type="submit"
                                                  className="btn btn-md btn-primary shadow-sm  mr-2"> Submit</button> :
                                <></>
                            }
                        </div>
                    </form>
                </div>
            </div>

        </div>


       

    </>)
}
export default PropertyManagement